const enMessages = {
  'modal.files': 'Drag and drop your files here',
  'modal.selector': 'Select',
  'modal.informacion': 'Input Data',
  'modal.carpeta': 'Create Folder',
  'modal.labelCarpeta': 'Folder name',
  'modal.nuevaCarpeta': 'New Folder',
  'modal.archivo': 'Upload File',
  'modal.zip': 'Upload Compressed File',
  'modal.propiedades': 'File/Folder Properties',
  'modal.carpetaRaiz': 'Root',
  'modal.visualizacion': 'There is no display for this file type',
  'menu.tablero': 'Board',
  'menu.empecemos': 'Lets start',
  'menu.transferir': 'Transfer',
  'menu.consultar': 'Consult',
  'menu.estadisticas': 'Statistics',
  'menu.compartidos': 'Shared',
  'menu.myshared':'My shared',
  'menu.recientes': 'Recent',
  'menu.papelera': 'Trash',
  'menu.dashboards': 'Dashboards',
  'menu.default': 'Default',
  'menu.visual': 'Visual',
  'menu.analytic': 'Analytic',
  'menu.apps': 'Apps',
  'menu.calendar': 'Calendar',
  'menu.chat': 'Chat',
  'menu.contacts': 'Contacts',
  'menu.mailbox': 'Mailbox',
  'menu.tasks': 'Tasks',
  'menu.pages': 'Pages',
  'menu.authentication': 'Authentication',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.forgot-password': 'Forgot Password',
  'menu.reset-password': 'Reset Password',
  'menu.blog': 'Blog',
  'menu.home': 'Home',
  'menu.grid': 'Grid',
  'menu.list': 'List',
  'menu.detail': 'Detail',
  'menu.miscellaneous': 'Miscellaneous',
  'menu.faq': 'Faq',
  'menu.knowledge-base': 'Knowledge Base',
  'menu.error': 'Error',
  'menu.coming-soon': 'Coming Soon',
  'menu.pricing': 'Pricing',
  'menu.search': 'Search',
  'menu.mailing': 'Mailing',
  'menu.empty': 'Empty',
  'menu.portfolio': 'Portfolio',
  'menu.cargar': 'Upload',
  'menu.profile': 'Profile',
  'menu.agregar' : 'New',
  'menu.edit' : 'Edit',
  'menu.delete' : 'Delete',
  'menu.standard': 'Standard',
  'menu.settings': 'Settings',
  'menu.blocks': 'Blocks',
  'menu.images': 'Images',
  'menu.tabular-data': 'Tabular Data',
  'menu.thumbnails': 'Thumbnails',
  'menu.cta': 'Cta',
  'menu.gallery': 'Gallery',
  'menu.stats': 'Stats',
  'menu.steps': 'Steps',
  'menu.details': 'Details',
  'menu.interfaz': 'T3rs Interface onefile',
  'menu.interfazmasvio' :'Interfaz T3rs (massive)',
  'menu.carpeta': 'New Folder',
  'menu.components': 'Components',
  'menu.accordion': 'Accordion',
  'menu.alerts': 'Alerts',
  'menu.badge': 'Badge',
  'menu.breadcrumb': 'Breadcrumb',
  'menu.buttons': 'Buttons',
  'menu.button-group': 'Button Group',
  'menu.card': 'Card',
  'menu.close-button': 'Close Button',
  'menu.copy' : 'Copy Image Link',
  'menu.close': 'Close',
  'menu.issue': 'Issues',
  'menu.collapse': 'Collapse',
  'menu.dropdowns': 'Dropdowns',
  'menu.list-group': 'List Group',
  'menu.modal': 'Modal',
  'menu.navs': 'Navs',
  'menu.offcanvas': 'Offcanvas',
  'menu.pagination': 'Pagination',
  'menu.popovers': 'Popovers',
  'menu.progress': 'Progress',
  'menu.spinners': 'Spinners',
  'menu.toasts': 'Toasts',
  'menu.tooltips': 'Tooltips',
  'menu.forms': 'Forms',
  'menu.layouts': 'Layouts',
  'menu.validation': 'Validation',
  'menu.wizard': 'Wizard',
  'menu.input-group': 'Input Group',
  'menu.input-mask': 'Input Mask',
  'menu.generic-forms': 'Generic Forms',
  'menu.controls': 'Controls',
  'menu.autocomplete': 'Autocomplete',
  'menu.checkbox-radio': 'Checkbox-Radio',
  'menu.date-picker': 'Date Picker',
  'menu.dropzone': 'Dropzone',
  'menu.editor': 'Editor',
  'menu.input-spinner': 'Input Spinner',
  'menu.rating': 'Rating',
  'menu.select': 'Select',
  'menu.slider': 'Slider',
  'menu.tags': 'Tags',
  'menu.time-picker': 'Time Picker',
  'menu.plugins': 'Plugins',
  'menu.carousel': 'Carousel',
  'menu.charts': 'Charts',
  'menu.clamp': 'Clamp',
  'menu.context-menu': 'Context Menu',
  'menu.datatables': 'Datatables',
  'menu.editable-rows': 'Editable Rows',
  'menu.editable-boxed': 'Editable Boxed',
  'menu.server-side': 'Server Side',
  'menu.boxed-variations': 'Boxed Variations',
  'menu.lightbox': 'Lightbox',
  'menu.maps': 'Maps',
  'menu.excel' : 'Load Excel Data',
  'menu.notification': 'Notification',
  'menu.novedad': 'Issue',
  'menu.type' : 'Types',
  'menu.subtype' : 'SubTypes',
  'tab.propiedades': 'Properties',
  'tab.actividades': 'Activity',
  'table.version': 'Version',
  'table.downloadVersion': 'versions to download',
  'table.name' : 'Name',
  'menu.typereason': 'Reason',
  'menu.players': 'Players',
  'menu.scrollbar': 'Scrollbar',
  'menu.shortcuts': 'Shortcuts',
  'menu.sortable': 'Sortable',
  'menu.content': 'Content',
  'menu.typeissue': 'Issue Type',
  'menu.icons': 'Icons',
  'menu.cs-line-icons': 'CS Line Icons',
  'menu.cs-interface-icons': 'CS Interface Icons',
  'menu.bootstrap-icons': 'Bootstrap Icons',
  'menu.tables': 'Tables',
  'menu.typography': 'Typography',
  'menu.menu': 'Menu',
  'menu.horizontal': 'Home',
  'menu.maestros':'Masters',
  'menu.programacion':'Schedule',
  'menu.perfiles':'Profiles',
  'menu.roles': 'Roles',
  'menu.shared':'Shared with me',
  'menu.cargos':'Positions',
  'menu.cargar':'Upload',
  'menu.rol': 'Roles',
  'menu.form': 'Form',
  'menu.permisos':'Permissions',
  'menu.servicios':'Services',
  'menu.incapacidades':'Disabilities',
  'menu.vacaciones':'Vacations',
  'menu.vertical': 'Vertical',
  'menu.vertical-hidden': 'Vertical Hidden',
  'menu.vertical-no-hidden': 'Vertical No Hidden',
  'menu.mobile-only': 'Mobile Only',
  'menu.sidebar': 'Sidebar',
  'menu.Docs': 'Docs',
  'menu.done': 'Done',
  'menu.connections': 'Connections',
  'menu.bookmarks': 'Bookmarks',
  'menu.requests': 'Requests',
  'menu.account': 'Account',
  'menu.password': 'Password',
  'menu.devices': 'Devices',
  'menu.notifications': 'Notifications',
  'menu.email': 'Email',
  'menu.sms': 'SMS',
  'menu.downloads': 'Downloads',
  'menu.documents': 'Documents',
  'menu.videos': 'Videos',
  'menu.dashboard': 'Dashboard',
  'menu.products': 'Products',
  'menu.orders': 'Orders',
  'menu.customers': 'Customers',
  'menu.storefront': 'Storefront',
  'menu.filters': 'Filters',
  'menu.categories': 'Categories',
  'menu.cart': 'Cart',
  'menu.checkout': 'Checkout',
  'menu.invoice': 'Invoice',
  'menu.shipping': 'Shipping',
  'menu.discount': 'Discount',
  'menu.general': 'General',

  'menu.upgrade': 'Upgrade',
  'menu.community': 'Community',
  'menu.community-list': 'Community List',
  'menu.getting-started': 'Getting Started',
  'menu.analysis': 'Analysis',
  'menu.services': 'Services',
  'menu.database': 'Database',
  'menu.database-add': 'New Database',
  'menu.database-detail': 'Database Detail',
  'menu.storage': 'Storage',
  'menu.hosting': 'Hosting',
  'menu.users': 'Users',
  'menu.billing': 'Billing',
  'menu.security': 'Security',
  'menu.support': 'Support',
  'menu.docs': 'Docs',
  'menu.docsdetail': 'Docs Detail',
  'menu.tickets': 'Tickets',
  'menu.tickets-detail': 'Teicket Detail',
  'menu.elearning': 'Elearning',
  'menu.school': 'School',
  'menu.courses': 'Courses',
  'menu.explore': 'Explore',
  'menu.quiz': 'Quiz',
  'menu.result': 'Result',
  'menu.paths': 'Paths',
  'menu.instructor': 'Instructor',
  'menu.misc': 'Miscellaneous',
  'menu.player': 'Player',
  'menu.material': 'Material',
  'menu.syllabus': 'Syllabus',
  'menu.ciudad' : 'City',
  'menu.categoria' : 'Categories',
  'menu.servicioscalendario' : 'Services Calendar',
  'menu.team': 'Teams',
  'menu.gestion': 'Management',
  'menu.ordentrabajo' : 'Work Order',
  'menu.taskschedual' : 'Schedual Task',
  'menu.assigntasks' :'Assign Tasks',
  'menu.parametro' :'Parameters',
  'menu.clientes' : 'Costumers',
  'menu.user' : 'Users',
  'menu.role' : 'Role',
  'menu.invoicecode' : 'Invoice',
  'table.compartidoPor': 'Shared by',
  'table.fechaDeCompartido': 'Share date',
  'table.fechaFinalizacion': 'Final date',
  'table.state' : 'State',
  'table.costumer' : 'Customer',
  'menu.changepassword' : 'Password Edit',
  'menu.confirmpassword' : 'Confirm  Password',
  'menu.updatepassword' : 'Edit Password',
  'menu.items' : 'elements',
  'table.code' : 'Code',
  'table.nombre' :'Name',
  'table.user': 'Owner',
  'menu.creationdate' : 'Creation Date',
  'table.active' :'Active',
  'table.cancel' :'Cancel',
  'table.done' : 'Done',
  'table.extension' : 'Extension (H)',
  'table.quote' : 'Quote',
  'table.init' : 'Init hour',
  'table.initdate' : 'Date - Init Hour',
  'table.finaldate' : 'Date - Final Hour',
  'table.final' : 'Final hour',
  'table.value': 'Value',
  'table.dafaultvalue' : 'Default value',
  'table.unit' : 'Unit',
  'table.email' : 'Email',
  'table.document' : 'Id',
  'table.mobilenumber' : 'Mobile Number',
  'table.address' : 'Address',
  'message.selector' : 'Select',
  'table.description' : 'Description',
  'table.password' : 'Password',
  'table.image' : 'Link image',
  'table.long' : 'Long Description',
  'table.short' : 'Short Description',
  'table.size' : 'Size',
  'table.user' : 'Owner',
  'table.lastModified' : 'Last Modified',
  'table.parentFolder' : 'Location',
  'menu.preview': 'Preview',
  'menu.title'  : 'Title',
  'menu.servicioscuadrilla' : 'Calendar Team', 
  'menu.impresionodt' : 'Print WO',
  'menu.tareas'  : 'Task',
  'menu.typeissue': 'Issue Type',
  'menu.download' : 'Download',
  'menu.load' : 'Load File',
  'menu.workhour' : 'Working Hours',
  'menu.observation' : 'Observation',
  'menu.employee': 'Employee',
  'menu.files'  : 'Files',
  'menu.query': 'Query',
  'menu.t3': 'T3 folders',
  'menu.clear': 'Clear Filter',
  'menu.initdate' : 'Init Date',
  'menu.finaldate' : 'Final Date',
  'menu.generate' : 'Generate',
  'menu.reportes'  : 'Reports',
  'menu.titleworkorder' : 'Work Order Management',
  'menu.titleissue' : 'Issue Management',
  'menu.serviciosnovedad' : 'Calendar Issue',
  'menu.agregartarea' :'Add Task',
  'menu.changestate' : 'Change state',
  'message.request': 'Ha ocurrido un error. El número de requerimiento no es valido, debe ser de máximo 5 dígitos',
  'message.id': 'Ha ocurrido un error. El número de documento no es valido, debe ser de mínimo 5 dígitos y máximo 10 dígitos',
  "message.startData": "Ha ocurrido un error. Se debe seleccionar una fecha inicial del documento",
  "message.endData": "Ha ocurrido un error. Se debe seleccionar una fecha final del documento",
  "message.date": "Ha ocurrido un error. Las fechas no son correctas. La fecha final debe ser mayor que la fecha inicial",
  'message.tareasadjuntas' : 'Should add at least one task, choose one and try again.',
  'message.title' : 'Title is not valid, try again.',
  'message.datetimeinit' : 'Start date Time is not valid, try again.',
  'message.datetimefinal' : 'End date Time  is not valid, try again.',
  'message.worktime' : 'Work Time is not valid, try again.',
  'message.delete' : 'Are you sure you want to delete the record?',
  'message.dates' : 'Start date must be greater than end date, try again.',
  'message.issue' :'The Issue is not valid (must be selected at least one option), try again.',
  'message.employee': 'The Employee is not valid (must be selected at least one option), try again.',
  'message.worktime' : 'The Worktime is not valid (must be selected at least one option), try again.',
  'message.name' : 'The field name is not valid (name must be at least 4 characters), try again.',
  'message.extension' : 'The field extension is not valid (must be at least 1 hour), try again.',
  'message.hours' :'The hours selected is not valid (Final hour should be more than Init hour), try again.',
  'message.city': 'The field city is not valid (must be selected at least one option), try again.',
  'message.wotype' : 'The field Issue type is not valid (must be selected at least one option), try again.',
  'message.costumer': 'The field customer is not valid (must be selected at least one option), try again.',
  'message.category': 'The field category is not valid (must be selected at least one option), try again.',
  'message.selected' : 'Select a city to load the available services',
  'message.unit' : 'Unit is not valid, try again.',
  'message.description' : 'Description  is not valid, ( must be at least 10 characters),try again.',
  'message.defaultvalue' : 'Default value is not valid, try again.',
  'message.parametervalue' : 'Parametere value is not valid, try again.',
  'message.email' : 'Email is not valid (email must be at least 5 characters and valid format), try again.',
  'message.document' : 'Id is not valid, try again.',
  'message.mobilenumber' : 'Mobile number is not valid, try again.',
  'message.address' : 'Address is not valid (address must be at least 5 characters), try again',
  'message.emptyform' : 'Information form is empty,try again.',
  'message.username' : 'User is not valid (user must be at least 8 characters, include one letter and one number), try again.',
  'message.profile': 'The field profile is not valid (must be selected at least one option), try again.',
  'message.role': 'The field role is not valid (must be selected at least one option), try again.',
  'message.password' : 'Password must contain at least one uppercase letter,one lowercase letter, one number and one special character, try again.',
  'message.user' : 'User must contain at least one uppercase letter,one lowercase letter and one number, try again.',
  'message.samepassword': 'The password does not match, please try again.',
  'message.team': 'The field user is not valid (must be selected at least one option), try again.',
  'message.services': 'Must be selected at least one option ( services ), try again.',
  'message.teams' : 'Teams number is not valid, try again.',
  'message.image' : 'Link image is not valid, try again.',
  'message.short' : 'Short Description is not valid,  try again.',
  'message.long' : 'Long Description is not valid, try again.',
  'message.value' : 'Value is not valid, try again.',
  'message.costumertype' : 'Customer Type is no valid, try again',
};
export default enMessages;
